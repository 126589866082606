<template>
  <module-container
    :title="$t('receipt.payment.emitted')"
    dense
    hide-new-button
    route-name="invoices.receipt"
    show-toolbar
  >
    <template #route>
      <invoices-list :hide-headers="['is_cash']" filter-key="receipt_emitted" />
    </template>

    <template #filters>
      <invoice-filters filter-key="receipt_emitted" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesList from "@/modules/invoices/components/InvoicesList.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";

@Component({
  components: { InvoiceFilters, InvoicesList },
})
export default class ReceiptEmitted extends Mixins(PaginateProviderMixin) {}
</script>
